import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.scss";

const Navbar = lazy(() => import("./comps/Navbar"));
const Home = lazy(() => import("./comps/home"));
const AboutUs = lazy(() => import("./comps/aboutus"));
const WhatWeDo = lazy(() => import("./comps/WhatWeDo"));
const Footer = lazy(() => import("./comps/footer"));
const Clients = lazy(() => import("./comps/clients"));

function App() {
  return (
    <BrowserRouter>
      <Suspense>
        <div className="App">
          <div className="route-wrapper">
            <Navbar />
            <Routes>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/what-we-do" element={<WhatWeDo />} />
                <Route path="/clients" element={<Clients />} />
              </Route>
            </Routes>
            <Footer />
          </div>
        </div>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
